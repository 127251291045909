import { LocalStorage } from "quasar"

/**
 * Set SYSTEM columns templates.
 *
 * @param state
 * @param formdata
 */
export function setSystemColumnsTemplates(state, formdata) {
  // fix if formdata is string null
  if (formdata === "null") {
    formdata = null
  }

  LocalStorage.set("systemColumnsTemplates", formdata)
  state.systemColumnsTemplates = formdata
}

/**
 * Set TEAM columns templates.
 *
 * @param state
 * @param formdata
 */
export function setTeamColumnsTemplates(state, formdata) {
  // fix if formdata is string null
  if (formdata === "null") {
    formdata = null
  }

  LocalStorage.set("teamColumnsTemplates", formdata)
  state.teamColumnsTemplates = formdata
}
