import { LocalStorage } from 'quasar';
import { MEASUREMENTS } from "src/constants";

/**
 * Add log
 */
export function ADD_LOG(state, value) {
  state.logs.push(value);
}

/**
 * Clear logs
 */
export function CLEAR_LOGS(state) {
  state.logs = [];
}

/**
 * Set scanning state
 */
export function SET_SCANNING(state, value) {
  state.scanning = !!value;
}

/**
 * Set receiving state
 */
export function SET_RECEIVING(state, value) {
  state.receiving = !!value;
}

/**
 * Set connected state
 */
export function SET_CONNECTED(state, value) {
  state.connected = !!value;
}

/**
 * Set device
 */
export function SET_DEVICE(state, device) {
  state.device = device;
}

/**
 * Clear device
 */
export function CLEAR_DEVICE(state) {
  state.device = null;
}

/**
 * Add measurement
 */
export function ADD_MEASUREMENT(state, value) {
  state.measurements.push(value);
  LocalStorage.set('bluetooth.measurements', state.measurements);

  // limit history
  if(state.measurements.length > MEASUREMENTS.MAX_HISTORY) {
    state.measurements.shift();
  }
}

/**
 * Clear measurements
 */
export function CLEAR_MEASUREMENTS(state) {
  state.measurements = [];
  LocalStorage.remove('bluetooth.measurements');
}
