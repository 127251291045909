import { store } from "quasar/wrappers"
import { createStore } from "vuex"
import app from "./app"
import auth from "./auth"
import project from "./project"
import phase from "./phase"
import columnTemplates from "./column-templates"
import order from "./order"
import bluetooth from "./bluetooth"


/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Store instance.
 */

export default store(function (/* { ssrContext } */) {
  return createStore({
    modules: {
      app,
      auth,
      project,
      phase,
      columnTemplates,
      order,
      bluetooth
    },

    // enable strict mode (adds overhead!)
    // for dev mode and --debug builds only
    strict: process.env.DEBUGGING
  })
})
