import { LocalStorage } from "quasar"

export default function () {
  return {
    folders: LocalStorage.getItem("folders") || null,
    projects: LocalStorage.getItem("projects") || null,
    currentProject: LocalStorage.getItem("currentProject") || null,
    rows: LocalStorage.getItem("rows") || null,
    blank_project: {
      name: null,
      team_id: null,
      client_name: null,
      is_private: false
    }
  }
}
