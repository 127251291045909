const routes = [
  {
    path: "/",
    component: () => import("layouts/MainLayout.vue"),
    children: [
      { path: "", redirect: "/dashboard" },
      { path: "dashboard", component: () => import("pages/Dashboard.vue") },
      { path: "project/:project", component: () => import("pages/project/Project.vue"), name: "project" },
      { path: "project/:project/phase/:phase", component: () => import("pages/phase/Phase.vue") },
      { path: "phase/create", component: () => import("pages/phase/NewPhase.vue"), name: "New Phase" },

      // settings
      { path: "settings", component: () => import("pages/settings/Settings.vue"), name: "Settings" },
      { path: "settings/profile", component: () => import("pages/settings/Profile.vue"), name: "My Profile" },
      { path: "settings/password", component: () => import("pages/settings/Password.vue"), name: "Change Password" },
      { path: "settings/teams", component: () => import("pages/settings/Teams.vue"), name: "My Teams" },
      { path: "settings/teams/:id", component: () => import("pages/settings/Team.vue") },
      { path: "settings/daily-reports", component: () => import("pages/settings/dailyReports.vue") }
    ],
    meta: { requiresAuth: true }
  },

  /* No auth required */
  {
    path: "/",
    component: () => import("layouts/MainLayout.vue"),
    children: [
      { path: "news", component: () => import("pages/News.vue"), name: "about" },
      { path: "login", component: () => import("pages/auth/Login.vue"), name: "login" },
      { path: "logout", component: () => import("pages/auth/Logout.vue"), name: "logout" },
      { path: "register", component: () => import("pages/auth/Register.vue"), name: "register" },
      { path: "verify", component: () => import("pages/auth/Verify.vue"), name: "verify" },
      { path: "reset", component: () => import("pages/auth/Reset.vue"), name: "reset-password" },
    ],
    meta: { requiresAuth: false }
  },

  /* Playground / Test pages */
  {
    path: "/playground",
    component: () => import("layouts/MainLayout.vue"),
    children: [
      { path: "dialog", component: () => import("src/pages/playground/Dialog.vue"), name: "Dialog" },
      { path: "speech", component: () => import("src/pages/playground/Speech.vue"), name: "Speech" },
      { path: "dvh", component: () => import("src/pages/playground/Dvh.vue"), name: "Dvh" },
      { path: "bluetooth", component: () => import("src/pages/playground/Bluetooth.vue"), name: "Bluetooth" },
    ],
    meta: { requiresAuth: false }
  },

  { path: "/playground/layout", component: () => import("src/pages/playground/Layout.vue"), name: "Layout" },
  { path: "/playground/camera", component: () => import("src/pages/playground/Camera.vue"), name: "camera" },

  // anything else goes to 404
  {
    path: "/:catchAll(.*)*",
    component: () => import("pages/errors/404.vue")
  }
]

export default routes
