import { Notify } from "quasar"

/**
 * Get columns templates.
 *
 * @param context
 * @param data
 * @returns {Promise<unknown>}
 */
export async function fetchColumnsTemplates(context, data) {
  console.log("Getting columns templates.")

  return await context
    .dispatch(
      "app/apiRequest",
      {
        url: "columns_templates",
        method: "GET",
        data: {},
        showSpinner: false
      },
      { root: true }
    )
    .then(async response => {
      if (response.data.team_columns_templates) {
        await context.commit("setSystemColumnsTemplates", response.data.system_columns_templates)
        await context.commit("setTeamColumnsTemplates", response.data.team_columns_templates)
      }

      return Promise.resolve(response.data)
    })
}

/**
 * Save new team Columns Template.
 *
 * @param context
 * @param data
 * @returns {Promise<unknown>}
 */
export async function saveColumnsTemplate(context, data) {
  return await context
    .dispatch(
      "app/apiRequest",
      {
        url: "columns_templates",
        method: "POST",
        data: {
          name: data.name,
          columns: data.columns
        }
      },
      { root: true }
    )
    .then(async response => {
      await context.commit("columnTemplates/setTeamColumnsTemplates", response.data.team_columns_templates, {
        root: true
      })
      Notify.create({ message: "Columns template saved" })
      return Promise.resolve(response.data)
    })
}

export async function updateColumnsTemplate(context, data) {
  return await context
    .dispatch(
      "app/apiRequest",
      {
        url: "columns_templates/" + data.id,
        method: "PUT",
        data: { ...data }
      },
      { root: true }
    )
    .then(async response => {
      Notify.create({ message: "Template updated" })
      await this.$store.dispatch("columnTemplates/fetchColumnsTemplates")
      return Promise.resolve(response.data)
    })
}

export async function deleteColumnsTemplate(context, data) {
  return await context
    .dispatch(
      "app/apiRequest",
      {
        url: "columns_templates",
        method: "DELETE",
        data: {
          id: data.id
        }
      },
      { root: true }
    )
    .then(async response => {
      Notify.create({ message: "Template deleted" })
      await this.$store.dispatch("columnTemplates/fetchColumnsTemplates")
      return Promise.resolve(response.data)
    })
}
