import { Loading, Notify, QSpinnerTail } from "quasar"
import { api } from "boot/axios"

export async function apiRequest({ getters, rootGetters }, {method, prefix, url, data, headers = {}, success = null}) {
  if (!getters.isOnline) {
    Notify.create({
      color: "negative",
      message: "No internet connection",
      icon: "error"
    })

    return Promise.reject("Not online...")
  }

  // inject instance id to data
  data = {
    ...data,
    instance_id: getters.instanceId
  }

  const currentTeam = rootGetters["auth/currentTeam"];
  if(currentTeam && currentTeam.id){
    headers = {
      ...headers,
      "X-Team-Id": currentTeam.id,
    }
  }

  return await api
    .request({
      method,
      url: (prefix ?? "/api/") + url,
      data,
      params: method.toLowerCase() === "get" ? data : null,
      headers: {
        ...headers,
        "Content-Type": "application/json"
      }
    })
    .then(response => {
      if (typeof success === "function") {
        return success(response, this.$router)
      }
      return Promise.resolve(response)
    })
    .catch(error => {

      if (
        error.response !== undefined &&
        error.response.data !== undefined &&
        error.response.data.errors !== undefined
      ) {
        for (const [key, err] of Object.entries(error.response.data.errors)) {
          Notify.create({
            color: "negative",
            message: err[0],
            icon: "error"
          })

          // show one error at a time
          break
        }
      } else {
        let message = error.message
        if (
          error.response !== undefined &&
          error.response.data !== undefined &&
          error.response.data.message !== undefined
        ) {
          message = error.response.data.message
        }
        Notify.create({
          color: "negative",
          message: message,
          icon: "mdi-alert-circle"
        })
      }

      return Promise.reject(error)
    })
}

/**
 * Get timezones.
 *
 * @param context
 * @param data
 * @returns {Promise<unknown>}
 */
export async function fetchTimezones(context, data) {
  return await context
    .dispatch(
      "app/apiRequest",
      {
        url: "timezones",
        method: "GET"
      },
      { root: true }
    )
    .then(async response => {
      return Promise.resolve(response.data)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

// set unique app instance id
export function setInstanceId(context) {
  const id = `instance_${Math.floor(Math.random() * 1000000)}`
  context.commit("SET_INSTANCE_ID", { id })
}
