import { LocalStorage } from 'quasar'

export default function () {
  return {
    scanning: false,
    connected: false,
    device: null,
    receiving: false,
    logs: [],
    measurements: LocalStorage.getItem('bluetooth.measurements') || []
  }
}
