import { boot } from "quasar/wrappers"
import axios from "axios"
import { Cookies, Notify } from "quasar"
import { datadogRum } from "@datadog/browser-rum"
import { Model } from 'vue-api-query'

// Be careful when using SSR for cross-request state pollution
// due to creating a Singleton instance here;
// If any client changes this (global) instance, it might be a
// good idea to move this instance creation inside of the
// "export default () => {}" function below (which runs individually
// for each client)
const api = axios.create({ baseURL: process.env.API_URL })
const s3 = axios.create({ baseURL: process.env.S3_URL })

export default boot(({ app, store, router }) => {
  // set default to use withCredentials
  api.defaults.withCredentials = true
  s3.defaults.withCredentials = false

  /**
   * Include DataDog RUM Session ID in all API requests to link frontend and backend logs.
   */
  api.interceptors.request.use((config) => {
    const rumSessionId = datadogRum.getInternalContext()?.session_id;
    console.log("RUM Session ID", rumSessionId);
    if (rumSessionId) {
      config.headers['x-datadog-trace-id'] = rumSessionId;
    }
    return config;
  });

  /**
   * Intercept every request to make sure we have a csrf token. If we don't then get a fresh token
   */
  api.interceptors.request.use(async req => {
    if (!store.state.auth.csrfToken && !Cookies.get("XSRF-TOKEN") && req.url != "/api/csrf-cookie") {
      await api.get("/api/csrf-cookie").catch(error => {
        Notify.create({
          color: "negative",
          message: error.response.data.message,
          icon: "mdi-alert-circle"
        })
        return Promise.reject()
      })
    }

    /**
     * Automatically append the authorization header
     */
    if (store.state.auth.userToken) {
      req.headers["Authorization"] = "Bearer " + store.state.auth.userToken
    }

    /**
     * Automatically append the Content type header if it's not set
     */
    req.headers["Content-Type"] = req.headers["Content-Type"] ? req.headers["Content-Type"] : "application/json"
    return Promise.resolve(req)
  })

  // for use inside Vue files (Options API) through this.$axios and this.$api

  app.config.globalProperties.$axios = axios
  // ^ ^ ^ this will allow you to use this.$axios (for Vue Options API form)
  //       so you won't necessarily have to import axios in each vue file

  app.config.globalProperties.$api = api
  // ^ ^ ^ this will allow you to use this.$api (for Vue Options API form)
  //       so you can easily perform requests against your app's API

  app.config.globalProperties.$s3 = s3
  // ^ ^ ^ this will allow you to use this.$s3 (for Vue Options API form)
  //       so you can easily perform requests against your app's API

  // inject global axios instance as http client to Model
  Model.$http = api
})

export { api, s3 }
