import { Notify } from "quasar"

/**
 * Get list of all orders for currentPhase.
 *
 * @param context
 * @returns {Promise<unknown>}
 */
export async function fetchCurrentPhaseOrders({dispatch, commit}) {

  // currentPhase id
  const phase_id = this.state.phase.currentPhase.id

  return await dispatch("app/apiRequest", {
    url: "orders",
    method: "GET",
    data: {
      phase_id
    }
  }, {root: true})
    .then(async response => {
      await commit("SET_ORDERS", response.data)
      return Promise.resolve(response.data)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

/**
 * Create order.
 *
 * @param context
 * @param data
 * @returns {Promise<*>}
 */
export async function create({dispatch, rootState}, { columns, rows, note }) {

  return await dispatch(
      "app/apiRequest",
      {
        url: "orders",
        method: "POST",
        data: {
          phase_id: rootState.phase.currentPhase.id,
          columns,
          rows,
          note,
        }
      },
      { root: true }
    )
    .then(response => {
      Notify.create({ message: "Order Created" })
      dispatch("fetchCurrentPhaseOrders")
      return Promise.resolve(response.data)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

/**
 * Update an order.
 *
 * @param context
 * @param data
 * @returns {Promise<unknown>}
 */
export async function update({dispatch}, data) {
  return await dispatch(
      "app/apiRequest",
      {
        url: "orders/" + data.order.hashid,
        method: "PUT",
        data: {
          order: data.order
        }
      },
      { root: true }
    )
    .then(response => {
      Notify.create({ message: "Order Updated" })
      dispatch("fetchCurrentPhaseOrders")
      return Promise.resolve(response.data)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

/**
 * Delete order phase.
 *
 * @param context
 * @param data
 * @returns {Promise<unknown>}
 */
export async function deleteOrder({dispatch}, data) {
  return await dispatch(
      "app/apiRequest",
      {
        url: "orders/" + data.order.hashid,
        method: "DELETE",
        data: data.order.hashid
      },
      { root: true }
    )
    .then(response => {
      Notify.create({ message: "Order Deleted" })
      dispatch("fetchCurrentPhaseOrders")
      return Promise.resolve(response.data)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}
