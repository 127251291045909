/**
 * Get whole state, which includes system and team templates.
 *
 * @param state
 * @returns {(function(): any)|*}
 */
export function templates(state) {
  return state
}

/**
 * Get a template by id.
 *
 * @param state
 * @returns {(function(*): (*))|*}
 */
export const getTemplateById = state => template_id => {
  const sysMatch = state.systemColumnsTemplates.find(template => template.id === template_id)
  if (sysMatch) {
    return sysMatch
  }

  return state.teamColumnsTemplates.find(template => template.id === template_id)
}

/**
 * Get team's default template.
 *
 * @param state
 * @returns {*}
 */
export function getDefaultTemplate(state) {
  const teamMatch = state.teamColumnsTemplates.find(template => template.is_team_default === true)
  if (teamMatch) {
    return teamMatch
  }

  return state.systemColumnsTemplates.find(template => template.id > 0)
}
