/**
 * Get list of orders in the current team.
 *
 * @param state
 * @returns {(function(): any)|*}
 */
export function orders(state) {
  return state.orders
}

/**
 * List of orders for rows.
 *
 * @param state
 * @returns []
 */
export const orderedRowsList = (state) => {
  let list = []

  state.orders.map(o => o.rows.map(r => {
    if (list.filter(item => item?.rowid === r?.rowid)?.length) {
      list.find(item => item?.rowid === r?.rowid)?.orders.push({
        order_id: o?.id,
        submitted_at: o?.submitted_at,
      })
    }
    else {
      list.push({
        rowid: r?.rowid,
        orders: [{
          order_id: o?.id,
          submitted_at: o?.submitted_at,
        }]
      })
    }
  }));

  return list;
}

/**
 * List of rowids that have been ordered already.
 *
 * @param state
 * @returns []
 */
export const orderedRowids = (state) => {
  return state.orders.map(o => o?.rows.map(r => r?.rowid)).flat() || []
}

/**
 * List of rowids that have not been ordered yet.
 *
 * @param state
 * @param getters
 * @param rootState
 * @param rootGetters
 * @returns []
 */
export const unorderedRowids = (state, getters, rootState, rootGetters) => {
  const rows = rootGetters["phase/currentPhase"].rows || []

  return rows.filter(row => {
    return !getters.orderedRowids.includes(row.rowid)
  })
}
