/**
 * Get list of folders in the current team.
 *
 * @param state
 * @returns {(function(): any)|*}
 */
export function folders(state) {
  const folders = JSON.parse(JSON.stringify(state.folders)) || []
  return folders.sort((a, b) => (a.order > b.order ? 1 : -1))
}

/**
 * Get list of projects in the current team.
 *
 * @param state
 * @returns {(function(): any)|*}
 */
export function projects(state) {
  const projects = JSON.parse(JSON.stringify(state.projects)) || []
  return projects.sort((a, b) => (a.name > b.name ? 1 : -1))
}

/**
 * Find project by id.
 *
 * @param state
 * @param getters
 * @returns array
 */
export const getProjectById = (state, getters) => project_id => {
  if (!Array.isArray(getters.projects)) return []

  return getters.projects.find(project => {
    return project.id === parseInt(project_id)
  })
}

/**
 * Get project numbers (installed counts by type, photo count, etc) for the given project id.
 *
 * @param state
 * @param getters
 * @param rootState
 * @param rootGetters
 * @returns {function(*=): {installed: number, total: number}}
 */
export const getProjectNumbersById = (state, getters, rootState, rootGetters) => project_id => {
  // reset counter before recalculating
  let counts = {
    total: 0,
    installed: 0,
  }

  const phases = rootGetters["phase/getPhasesByProjectId"](project_id) || []

  phases.forEach(phase => {

    const rows = Array.isArray(phase.rows) ? phase.rows : []

    rows.forEach(row => {
      const type = row["type"]

      if (!(type in counts)) {
        counts[type] = {
          total: 0,
          installed: 0
        }
      }

      counts.total++
      counts[type].total++

      if((typeof row?.installed === 'object' && row?.installed?.value) || row?.installed === true || row?.installed === 'true'){
        counts.installed++
        counts[type].installed++
      }

      // sort
      counts = Object.keys(counts)
        .sort()
        .reduce((obj, key) => {
          obj[key] = counts[key]
          return obj
        }, {})
    })
  })

  return counts
}

// currentProject
export function currentProject(state) {
  return state.currentProject
}

// rows
export function rows(state) {
  return state.rows
}
