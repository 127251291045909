export default ({app}) => {
  app.directive("longpress", {
    beforeMount(el, binding) {
      // make sure the value passed is an array of two functions or a single function
      if (!(Array.isArray(binding.value) && binding.value.length === 2 && binding.value.every(fn => typeof fn === "function")) && typeof binding.value !== "function") {
        let warn = `[longpress:] provided expression must be a function, or an array of two functions (first one for short press, second one for long press).`
        console.warn(warn)
        return;
      }

      let pressTimer = null
      let longPressTriggered = false

      // define function to start the timer
      el.start = e => {
        if (e.type === "click" && e.button !== 0) {
          return
        }

        if (pressTimer === null) {
          pressTimer = setTimeout(() => {
            e.stopPropagation()
            // execute the second function passed as value to the directive
            if (Array.isArray(binding.value)) {
              binding.value[1](e)
            } else if (typeof binding.value === "function") {
              binding.value(e)
            }
            longPressTriggered = true
          }, 500) // set timeout for 0.5 seconds
        }
      }

      // define function to cancel the timer
      el.cancel = e => {
        if (pressTimer !== null) {
          clearTimeout(pressTimer)
          pressTimer = null
          // execute the first function passed as value to the directive
          // only if the long press handler has not been executed
          if (!longPressTriggered && Array.isArray(binding.value)) {
            binding.value[0](e)
          }
        }
        longPressTriggered = false // reset the flag
      }

      // add event listeners
      el.addEventListener("mousedown", el.start)
      el.addEventListener("touchstart", el.start)
      el.addEventListener("click", el.cancel)
      el.addEventListener("mouseout", el.cancel)
    },
    unmounted(el) {
      el.removeEventListener("mousedown", el.start)
      el.removeEventListener("touchstart", el.start)
      el.removeEventListener("click", el.cancel)
      el.removeEventListener("mouseout", el.cancel)
    }
  })
}
