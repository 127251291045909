/**
 * THIS FILE IS GENERATED AUTOMATICALLY.
 * DO NOT EDIT.
 *
 * You are probably looking on adding startup/initialization code.
 * Use "quasar new boot <name>" and add it there.
 * One boot file per concern. Then reference the file(s) in quasar.conf.js > boot:
 * boot: ['file', ...] // do not add ".js" extension to it.
 *
 * Boot files are your "main.js"
 **/

import iconSet from 'quasar/icon-set/mdi-v7'



import {Notify,Dialog,AppVisibility} from 'quasar'



export default { config: {"loading":{"message":"Doing something. Please wait...","boxClass":"bg-grey-2 text-grey-9","spinnerColor":"primary"},"notify":{"color":"positive","position":"bottom","progress":true,"icon":"check_circle","timeout":2000}},iconSet,plugins: {Notify,Dialog,AppVisibility} }

