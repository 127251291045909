/**
 * Checks whether user is logged in.
 *
 * @param state
 * @returns {boolean}
 */
export function isLoggedIn(state) {
  if (!state.userToken || state.userToken === "null") {
    return false
  } else {
    return true
  }
}

/**
 * Get current user.
 *
 * @returns {Object}
 */
export function user(state) {
  return state.user
}

/**
 * Get user's teams.
 *
 * @returns []
 */
export function teams(state) {
  return state.teams || []
}

/**
 * Get user's current team.
 *
 * @returns {Object}
 */
export function currentTeam(state) {
  return state.currentTeam || []
}

/**
 * Checks if logged-in user is a developer.
 *
 * @param state
 * @param getters
 * @returns boolean
 */
export function isDeveloper(state, getters) {
  const developers = ["sage@aurashades.com", "sagearslan@gmail.com"]
  return getters.isLoggedIn && developers.includes(getters.user.email)
}

/**
 * Get User ID.
 *
 * @param {*} state
 * @returns
 */
export function userId(state) {
  return state.user.id
}

/**
 * Get direct-to-S3 signature as FormData.
 *
 * @param state
 * @returns {FormData}
 */
export function directS3FormData(state) {
  let bodyFormData = new FormData()

  if (state.directS3FormData && state.directS3FormData !== "null") {
    for (const key in state.directS3FormData) {
      bodyFormData.append(key, state.directS3FormData[key])
    }
  }

  return bodyFormData
}

/**
 * Get user invites
 */
export function invites(state) {
  return state.invites
}

/**
 * Get team users
 *
 * @param state
 * @returns {Array}
 */
export function teamUsers(state) {
  return state.currentTeam.users
}

// get team user by id
export function teamUserById(state) {
  return (id) => {
    return state.currentTeam.users.find((user) => user.id === id)
  }
}
