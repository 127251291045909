<template>
  <router-view />
</template>
<script>
import { defineComponent } from "vue"
import { datadogRum } from "@datadog/browser-rum"

export default defineComponent({
  name: "App",
  async created() {
    // allows differentiating instances, especially for users with multiple tabs open.
    // also used to ignore own Pusher events
    await this.$store.dispatch("app/setInstanceId")

    // if logged in
    if (this.$store.getters["auth/isLoggedIn"]) {
      await this.$store.dispatch("auth/getUser")
      await this.$store.dispatch("auth/getTeams")
      await this.$store.dispatch("auth/getCurrentTeam")
    }

    datadogRum.init({
      applicationId: process.env.DATADOG_APP_ID,
      clientToken: process.env.DATADOG_CLIENT_TOKEN,
      site: process.env.DATADOG_SITE,
      service: process.env.DATADOG_SERVICE,
      env: process.env.APP_ENV,
      // version: '1.0.0',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "allow"
    })

    datadogRum.startSessionReplayRecording()
  }
})
</script>
