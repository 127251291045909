import { LocalStorage } from "quasar"
import { generateUuid } from "src/boot/helpers"

/**
 * Helper to update data object.
 *
 * @param state
 * @param data
 */
export function update(state, data) {
  Object.keys(data).forEach(key => {
    if (!key.includes(".")) {
      state[key] = data[key]
    } else {
      updateDeep(state, key.split("."), data[key])
    }
  })
}

/**
 * Helper to update nested data object.
 *
 * @param obj
 * @param keys
 * @param value
 * @returns {*}
 */
function updateDeep(obj, keys, value) {
  if (keys.length === 1) {
    obj[keys[0]] = value
  } else {
    let currentKey = keys.shift()
    obj[currentKey] = updateDeep(obj[currentKey], keys, value)
  }
  return obj
}

/**
 * Set phases list to local storage.
 *
 * @param state
 * @param formdata
 */
export function SET_PHASES(state, formdata) {
  // update state only if data is different from existing state
  if (JSON.stringify(state.phases) !== JSON.stringify(formdata.data)) {
    console.log("mutation: SET_PHASES() - data is different from existing state, updating state")

    state.phases = [...formdata.data]
    LocalStorage.set("phase.phases", [...formdata.data])
  } else {
    console.log("mutation: SET_PHASES() - data is same as existing state, skipping update")
  }
}

/**
 * Set current Phase to state.
 *
 * @param state
 * @param data
 */
export function SET_CURRENT_PHASE(state, data) {
  console.log("mutation: SET_CURRENT_PHASE() - setting current phase to state", data)
  state.currentPhase = data
}

/**
 * Set phase rows.
 */
export function SET_CELL_VALUE(state, { column_id, rowid, value }) {
  if (state.currentPhase) {
    let phase = state.currentPhase
    let row = phase.rows.find(o => o.rowid === rowid)

    if (row) {
      row[column_id] = value

      // update in both vuex state and persist in localstorage
      state.currentPhase.rows = phase.rows
    }
  }
}

/**
 * Set inches numpad visibility.
 * @param state
 * @param isVisible
 */
export function SET_NUMPAD_VISIBILITY(state, isVisible) {
  state.numpadVisible = isVisible
}

// add a row
export function ADD_ROW(state, { row, index }) {
  // if no index is provided, add to the end of the array
  if (index === undefined) {
    state.currentPhase.rows.push({ ...row })
  } else {
    state.currentPhase.rows.splice(index, 0, { ...row })
  }
}

/**
 * Deletes row(s) from the state's currentPhase.rows array using rowid.
 *
 * @param {Object} state - The Vuex state object.
 * @param {Object} payload - The payload object containing the rowid of the row to be deleted.
 * @param {string} payload.rowid - The unique identifier of the row to be deleted.
 */
export function DELETE_ROWS_BY_ROWID(state, { rowids }) {
  state.currentPhase.rows = state.currentPhase.rows.filter(row => !rowids.includes(row.rowid))

  // clear selected rows in case someone else adds or deletes rows
  // todo: maybe use rowids to denote selected rows instead of row indexes
  state.selectedRows = []
}

/**
 * Deletes multiple rows by index.
 *
 * @param {Object} state - The state object.
 * @param {Array<number>} rows - The array of row indexes to delete.
 */
export function DELETE_ROWS_BY_INDEX(state, row_indexes) {
  state.currentPhase.rows = state.currentPhase.rows.filter((row, index) => !row_indexes.includes(index))

  // remove row indexes from selectedRows
  state.selectedRows = state.selectedRows.filter(selectedIndex => !row_indexes.includes(selectedIndex))
}

/**
 * Update a phase using phase.id
 *
 * @param state
 * @param phase
 * @constructor
 */
export function UPDATE_PHASE(state, { phase }) {
  phase = JSON.parse(JSON.stringify(phase))

  // get phase index
  const phaseIndex = state.phases.findIndex(p => p.id === phase.id)

  // update phase
  state.phases[phaseIndex] = phase

  // update current phase if it is the same phase
  if(state.currentPhase.id === phase.id){
    state.currentPhase = phase
  }
}

/**
 * Updates the columns of the current phase in the state.
 *
 * @param {Object} state - The Vuex state object.
 * @param {Object} payload - The payload object containing the columns to be updated.
 * @param {Array} payload.columns - The new columns to be set for the current phase.
 */
export function UPDATE_PHASE_COLUMNS(state, { columns }) {
  console.log("UPDATE_PHASE_COLUMNS", columns)
  state.currentPhase.columns = columns
}

/**
 * Updates the rows of the current phase in the state.
 *
 * @param {Object} state - The Vuex state object.
 * @param {Object} payload - The payload object containing the rows to be updated.
 * @param {Array} payload.rows - The new rows to be assigned to the current phase.
 */
export function UPDATE_PHASE_ROWS(state, { rows }) {
  state.currentPhase.rows = rows
}

/**
 * Toggle the selection of a row.
 *
 * @param state
 * @param rowIndex
 */
export function TOGGLE_ROW_SELECTION(state, rowIndex) {
  const index = state.selectedRows.indexOf(rowIndex)
  if (index === -1) {
    state.selectedRows.push(rowIndex)
  } else {
    state.selectedRows.splice(index, 1)
  }
}

/**
 * Set the selected rows.
 *
 * @param state
 * @param selectedRows
 */
export function SET_SELECTED_ROWS(state, selectedRows) {
  state.selectedRows = selectedRows
}

/**
 * Clear the selected rows.
 *
 * @param state
 */
export function CLEAR_SELECTED_ROWS(state) {
  state.selectedRows = []
}

/**
 * Sets the value of the last selected row in datatable.
 *
 * @param {Object} state - The Vuex state object.
 * @param {any} lastSelectedRow - The value to set as the last selected row.
 */
export function SET_LAST_SELECTED_ROW(state, lastSelectedRow) {
  state.lastSelectedRow = lastSelectedRow
}

/**
 * Sets the value of the last selected row in datatable.
 *
 * @param {Object} state - The Vuex state object.
 * @param {any} lastSelectedRow - The value to set as the last selected row.
 */
export function DELETE_COLUMN(state, column_id) {
  state.currentPhase.columns = state.currentPhase.columns.filter(column => column.id !== column_id)
  state.currentPhase.rows.forEach(row => {
    delete row[column_id]
  })
}

/**
 * Sets the value of upload percentage for a photo being uploaded to S3.
 *
 * @param state
 * @param phase_id
 * @param column_id
 * @param rowid
 * @param indexInCell
 * @param progress
 * @constructor
 */
export function UPDATE_PHOTO_UPLOAD_PROGRESS(state, { phase_id, column_id, rowid, indexInCell, progress }) {
  const phase = state.phases.find(phase => phase.id === phase_id)
  if (phase) {
    const row = phase.rows.find(row => row.rowid === rowid)
    if (row) {
      const cellValue = row[column_id]
      if (cellValue && Array.isArray(cellValue) && indexInCell >= 0 && indexInCell < cellValue.length) {
        const photoInCell = cellValue[indexInCell]
        cellValue[indexInCell] = {
          ...photoInCell,
          uploadProgress: progress
        }
      }
    }
  }
}

export function ADD_TO_QUEUE(state, queue_item) {
  if(!Array.isArray(state.queue)){
    state.queue = [];
  }

  // generate random id for queue item
  queue_item.id = generateUuid();
  queue_item.status = "pending"

  state.queue.push(queue_item);
}

export function CLEAR_QUEUE(state, processed_ids = null) {

  // remove only processed items from queue
  if (processed_ids) {
    state.queue = state.queue.filter(item => !processed_ids.includes(item.id))
    return;
  }

  state.queue = []
}

export function SET_QUEUE_ITEMS_STATUS(state, { queue_items, status }) {
  state.queue.forEach(item => {
    if(queue_items.includes(item.id)){
      item.status = status;
    }
  })
}
