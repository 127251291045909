export const COLUMN_TYPES = [
  {
    id: "text",
    name: "Short Text"
  },
  {
    id: "longtext",
    name: "Long Text"
  },
  {
    id: "subindex",
    name: "Sub Index",
    computed: true
  },
  {
    id: "measurement",
    name: "Measurement (inches)"
  },
  {
    id: "select",
    name: "Single Select (dropdown)"
  },
  {
    id: "checkbox",
    name: "Checkbox"
  },
  {
    id: "photo",
    name: "Photo"
  },
  {
    id: "orders",
    name: "Orders",
    computed: true
  }
]

// Disto laser measurement service
// more info:
// - https://github.com/murkle/utils/blob/master/webbluetooth/leica_distoD2_laser_measurer.html
// - https://stackoverflow.com/questions/69629692/how-to-read-laser-distance-measure-via-web-bluetooth
export const DISTO = {
  NAME_PREFIX: "DISTO ",
  DISTO_SERVICEID: '3ab10100-f831-4395-b29d-570977d5bf94',
  DISTO_DISTANCE: "3ab10101-f831-4395-b29d-570977d5bf94",
  DISTO_DISTANCE_UNIT: "3ab10102-f831-4395-b29d-570977d5bf94",
  DISTO_COMMAND: "3ab10109-f831-4395-b29d-570977d5bf94",
  STATE_RESPONSE: "3ab1010a-f831-4395-b29d-570977d5bf94",
  DS_MODEL_NAME: "3ab1010c-f831-4395-b29d-570977d5bf94",
  BATTERY_SERVICE: '0000180f-0000-1000-8000-00805f9b34fb',
  DEVICE_INFORMATION: '0000180a-0000-1000-8000-00805f9b34fb'
}

export const MEASUREMENTS = {
  MAX_HISTORY: 4
}

export const DEFAULT_COLUMN = `text`
