import { route } from "quasar/wrappers"
import { createRouter, createMemoryHistory, createWebHistory, createWebHashHistory } from "vue-router"
import routes from "./routes"
import store from "../store"
import { LocalStorage } from "quasar"

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

export default route(function (/* { store, ssrContext } */) {
  const createHistory = process.env.SERVER
    ? createMemoryHistory
    : process.env.VUE_ROUTER_MODE === "history"
    ? createWebHistory
    : createWebHashHistory

  const Router = createRouter({
    scrollBehavior: () => ({ left: 0, top: 0 }),
    routes,

    // Leave this as is and make changes in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(process.env.MODE === "ssr" ? void 0 : process.env.VUE_ROUTER_BASE)
  })

  /**
   * Make sure the user is logged in for protected routes
   */
  Router.beforeEach((to, from, next) => {
    to.matched.some(record => {
      const loggedIn = store().getters["auth/isLoggedIn"]
      const user = store().getters["auth/getUser"]
      if ("meta" in record) {
        if ("requiresAuth" in record.meta) {
          if (record.meta.requiresAuth && !loggedIn) {
            next({
              path: "/login",
              replace: true,
              query: { redirect: to.fullPath }
            })
          } else if (
            user &&
            loggedIn &&
            (!user.email_verified_at || user.email_verified_at === "") &&
            to.path !== "/verify/email"
          ) {
            next({
              path: "/verify/email",
              replace: true,
              query: { redirect: to.fullPath }
            })
          }
        }
      }
    })
    document.title = to.title || to.name || "BlindTools"
    next()
  })

  return Router
})
