import { LocalStorage } from "quasar"

/**
 * Sets user token into localstorage for persisting authentication.
 *
 * @param state
 * @param token
 */
export function SET_USER_TOKEN(state, token) {
  // fix if token is string null
  if (token === "null") {
    token = null
  }
  LocalStorage.set("userToken", token)
  state.userToken = token
}

export function setInviteToken(state, token) {
  // fix if token is string null
  if (token === "null" || token === undefined) {
    token = null
  }

  if (token) {
    LocalStorage.set("inviteToken", token)
    state.inviteToken = token
  }
}

export function clearInviteToken(state) {
  LocalStorage.remove("inviteToken")
  delete state.inviteToken
}

/**
 * Sets user object into localstorage to easily reference logged in user.
 * @param state
 * @param user
 */
export function SET_USER(state, user) {
  // fix if user is string null
  if (user === "null") {
    user = null
  }
  LocalStorage.set("user", user)
  state.user = user
}

/**
 * Sets user's teams.
 *
 * @param state
 * @param teams
 * @constructor
 */
export function SET_TEAMS(state, teams) {
  if (!Array.isArray(teams)) {
    teams = []
  }

  LocalStorage.set("teams", teams)
  state.teams = teams
}

/**
 * Sets user's current team.
 *
 * @param state
 * @param team
 * @constructor
 */
export function SET_CURRENT_TEAM(state, team) {
  if (typeof team !== "object") {
    team = {}
  }

  LocalStorage.set("currentTeam", team)
  state.currentTeam = team
}

/**
 * Update team object.
 *
 * @param state
 * @param team
 */
export function UPDATE_TEAM(state, team) {
  // update team based on id
  let teams = state.teams
  let index = teams.findIndex(t => t.id === team.id)
  teams[index] = team
}

/**
 * Sets user object into localstorage to easily reference logged in user.
 * @param state
 * @param timezone
 */
export function SET_USER_TIMEZONE(state, timezone) {
  // fix if user is string null
  if (timezone === "null") {
    timezone = null
  }

  state.user.timezone = timezone
  LocalStorage.set("user", state.user)
}

/**
 * Set invitations received.
 * @param state
 * @param invites
 */
export function setInvites(state, invites) {
  // fix if invites is string null
  if (invites === "null") {
    invites = null
  }
  state.invites = invites
}

/**
 * Helper to update data object.
 *
 * @param state
 * @param data
 */
export function update(state, data) {
  Object.keys(data).forEach(key => {
    if (!key.includes(".")) {
      state[key] = data[key]
    } else {
      updateDeep(state, key.split("."), data[key])
    }
  })
}

/**
 * Helper to update nested data object.
 *
 * @param obj
 * @param keys
 * @param value
 * @returns {*}
 */
function updateDeep(obj, keys, value) {
  if (keys.length === 1) {
    obj[keys[0]] = value
  } else {
    let currentKey = keys.shift()
    obj[currentKey] = updateDeep(obj[currentKey], keys, value)
  }
  return obj
}

/**
 * Set new S3 signature.
 *
 * @param state
 * @param formdata
 */
export function UPDATE_S3_FORM_DATA(state, formdata) {
  // fix if formdata is string null
  if (formdata === "null") {
    formdata = null
  }

  LocalStorage.set("directS3FormData", formdata)
  state.directS3FormData = formdata
}
