/**
 * Add global helpers and mixins here.
 */

import { boot } from "quasar/wrappers"
import { date, exportFile, Notify } from "quasar"

/**
 * Generate a random integer within a range.
 *
 * @param min
 * @param max
 * @returns {number}
 */
const randomInt = (min = 0, max = 9999) => {
  return (Math.random() * (max - min + 1)) << 0
}

/**
 * Get a random alphanumeric string.
 *
 * @param length
 * @param chars
 * @returns {string}
 */
const randomStr = (length = 6, chars) => {
  chars = chars ?? "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"

  var result = ""
  var charsLength = chars.length
  for (var i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * charsLength))
  }
  return result
}

/**
 * Get S3 url with key or not
 *
 * @param key
 * @returns {*}
 */
const getS3Url = (key = "") => {
  return process.env.S3_URL + key
}

/**
 * Generate a UUID. A *universal* unique id.
 *
 * @param placeholder
 * @returns {string|*}
 */
const generateUuid = (placeholder = "") => {
  return placeholder
    ? (placeholder ^ ((Math.random() * 16) >> (placeholder / 4))).toString(16)
    : ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, generateUuid)
}

/**
 * Generate an row ID for measurements table.
 *
 * @param min
 * @param max
 * @param exclude array of values to exclude
 * @returns {boolean|int} false is error
 */
const generateUniqueInt = ({ min = 0, max = 9999, exclude = [] } = {}) => {
  // error handling
  if (min > max) {
    throw "generateUniqueInt: Min cannot be greater than max."
  }

  if (!Array.isArray(exclude)) {
    throw "generateUniqueInt: `exclude` must be an array"
  }

  // roll the dice
  const val = Math.floor(Math.random() * (max - min + 1)) + min

  // roll again if generated val exists
  if (exclude.length && exclude.includes(val)) {
    return generateUniqueInt({ min, max, exclude })
  }

  // success!
  return val
}

/**
 * Create sequential ID integer.
 *
 * @param start
 * @param exclude
 * @returns {number}
 */
const generateSequentialId = ({ start = 0, exclude = [] } = {}) => {
  // error handling
  if (!Array.isArray(exclude)) {
    throw "generateSequentialId: `exclude` must be an array"
  }

  // default starting value
  let val = Number(start) || 0

  // ah there's an exclude list
  if (exclude.length > 0) {
    // get max value in exclude list + 1
    val = Math.max(...exclude) + 1
  }

  // success!
  val = Math.max(...[val, start])
  return parseInt(val);
}

/**
 * Add leading zeros to number.
 *
 * @param num
 * @param size
 * @returns {string}
 */
function padNum(num, size) {
  num = num.toString()
  while (num.length < size) num = "0" + num
  return String(num)
}

/**
 * Format a date to human readable.
 *
 * @param val
 * @param format
 * @returns {string}
 */
const formatDate = (val, format) => {
  return date.formatDate(val, format || "MMM D YYYY")
}

/**
 * Wrap CSV cell values for wrapping.
 *
 * @param val
 * @param formatFn
 * @returns {`"${string}"`}
 */
const wrapCsvValue = (val, formatFn) => {
  let formatted = formatFn !== void 0 ? formatFn(val) : val

  formatted = formatted === void 0 || formatted === null ? "" : String(formatted)

  formatted = formatted.split('"').join('""')
  /**
   * Excel accepts \n and \r in strings, but some other CSV parsers do not
   * Uncomment the next two lines to escape new lines
   */
  // .split('\n').join('\\n')
  // .split('\r').join('\\r')

  return `"${formatted}"`
}

/**
 * Export a datatable by supplying columns and rows data.
 *
 * @param columns
 * @param rows
 */
const exportTable = (columns, rows, name) => {
  // default filename
  name = (name ?? "table-export") + ".csv"

  // naive encoding to csv format
  const content = [columns.map(col => wrapCsvValue(col.label))]
    .concat(
      rows.map(row =>
        columns
          .map(col =>
            wrapCsvValue(
              typeof col.field === "function" ? col.field(row) : row[col.field === void 0 ? col.name : col.field],
              col.format
            )
          )
          .join(",")
      )
    )
    .join("\r\n")

  const status = exportFile(name.trim(), content, "text/csv")

  if (status !== true) {
    Notify.create({
      type: "negative",
      icon: "warning",
      message: "Browser denied file download."
    })
  } else {
    Notify.create({
      type: "positive",
      icon: "mdi-check",
      message: "Table exported."
    })
  }
}

/**
 * Rounds a decimal input up or down to the nearest specified precision.
 *
 * Example: dec2precision(56.43,.25,'down') returns 56.25
 *
 * @param value {number}
 * @param precision {number}
 * @param updown {('up'|'down')}
 * @returns {number}
 */
const dec2precision = (value, precision, updown = "down") => {
  value = frac2dec(value)
  precision = parseFloat(precision)

  if (!value || !precision) {
    return value
  }

  if (updown && updown === "up") {
    return Math.ceil(value / precision) * precision
  }

  return Math.floor(value / precision) * precision
}

/**
 * Converts a fractional measurement string (with or without a whole number in front) to a decimal
 * Example: `56 1/2` returns 56.5
 *
 * Returns original value if input is malformed.
 *
 * @param fraction {string}
 * @returns {number|string}
 */
const frac2dec = fraction => {
  fraction = String(fraction)
  var result,
    wholeNum = 0,
    frac,
    deci = 0
  if (fraction.search("/") >= 0) {
    if (fraction.search(" ") >= 0) {
      wholeNum = fraction.split(" ")
      frac = wholeNum[1]
      wholeNum = parseInt(wholeNum, 10)
    } else {
      frac = fraction
    }
    if (fraction.search("/") >= 0 && frac !== undefined) {
      frac = frac.split("/")
      deci = parseInt(frac[0], 10) / parseInt(frac[1], 10)
    }
    result = wholeNum + deci
  } else {
    result = fraction
  }
  return result
}

/**
 * Converts a decimal value to a fraction.
 *
 * Example: `56.25` returns 56 1/4
 *
 * @param value
 * @returns {string|number}
 */
const dec2frac = value => {
  if (!value) return ""

  var Fraction = require("fractional").Fraction
  const frac = new Fraction(value)
  return String(frac)
}

/**
 * Convert meters to inches and allow fractional inch precision.
 */
const metersToInches = (meters, precision = 1 / 32) => {
  // convert meters to inches
  const inches = meters * 39.3700787402

  // round to precision
  return Math.round(inches / precision) * precision
}

/**
 * Validates if a string is an integer with optional fractional inch value up to 1/32 precision.
 * Valid: 12 1/8
 * Valid: 24 3/16
 * Valid: 36 5/32
 * Valid: 36
 * Invalid: 48 2/4
 * Invalid: 60 1/64
 * Invalid: 72 5/7
 * Invalid: 84.5
 *
 * @param value
 * @returns {boolean}
 */
const isValidFractionalInch = value => {

  // ignore empty values
  if(!value) return true;

  // checks for valid inches input - full inch value followed by optional fraction in 1/8th, 1/16th, or 1/32 precision
  const regex = /^(\d+)(?: (1\/[248]|3\/[48]|5\/8|7\/8|9\/16|11\/16|13\/16|15\/16|1\/16|3\/16|5\/16|7\/16|1\/32|3\/32|5\/32|7\/32|9\/32|11\/32|13\/32|15\/32|17\/32|19\/32|21\/32|23\/32|25\/32|27\/32|29\/32|31\/32))?$/;
  return regex.test(value);
}

/**
 * Generate a hash based on input string.
 * Used for generating index keys for subindex column type.
 *
 * @param input
 * @returns {string}
 */
const generateHash = async input => {
  const encoder = new TextEncoder();
  const data = encoder.encode(input);
  const hash = await crypto.subtle.digest('SHA-256', data);
  return Array.from(new Uint8Array(hash)).map(b => b.toString(16).padStart(2, '0')).join('');
}

/**
 * Export helpers to allow importing as functions.
 */
export {
  generateUuid,
}

/**
 * Add mixin to Vue instance
 */
export default boot(({ app }) => {
  app.mixin({
    methods: {
      randomInt,
      randomStr,
      getS3Url,
      generateUuid,
      formatDate,
      exportTable,
      generateUniqueInt,
      generateSequentialId,
      padNum,
      dec2precision,
      frac2dec,
      dec2frac,
      isValidFractionalInch,
      generateHash,
      metersToInches
    }
  })
})
