const DB_NAME = "imagesDB"
const DB_VERSION = 1
const TABLE = "images"
let DB

export default {
  async getDb() {
    return new Promise((resolve, reject) => {
      if (DB) {
        return resolve(DB)
      }
      console.log("OPENING DB", DB)
      let request = window.indexedDB.open(DB_NAME, DB_VERSION)

      request.onerror = e => {
        console.log("Error opening db", e)
        reject("Error")
      }

      request.onsuccess = e => {
        DB = e.target.result
        resolve(DB)
      }

      request.onupgradeneeded = e => {
        console.log("onupgradeneeded")
        let db = e.target.result
        db.createObjectStore(TABLE, { autoIncrement: true, keyPath: "id" })
      }
    })
  },

  /**
   * Deletes an entry.
   *
   * @param {Object} options
   * @param {number} options.id
   * @returns {Promise<void>}
   */
  async deleteEntry({ id }) {
    if (!id) {
      throw new Error("`id` is required to delete entry from IndexDB.")
    }

    let db = await this.getDb()

    return new Promise(resolve => {
      let trans = db.transaction([TABLE], "readwrite")
      trans.oncomplete = () => {
        resolve()
      }

      let store = trans.objectStore(TABLE)
      store.delete(id)
    })
  },

  /**
   * Retrieves all entries.
   *
   * @returns {Promise<Array<Object>>}
   */
  async getAll() {
    let db = await this.getDb()

    return new Promise(resolve => {
      let trans = db.transaction([TABLE], "readonly")
      trans.oncomplete = () => {
        resolve(entries)
      }

      let store = trans.objectStore(TABLE)
      let entries = []

      store.openCursor().onsuccess = e => {
        let cursor = e.target.result
        if (cursor) {
          entries.push(cursor.value)
          cursor.continue()
        }
      }
    })
  },


  getEntryById(id) {
    return this.getDb().then(db => {
      return new Promise((resolve, reject) => {
        let trans = db.transaction([TABLE], "readonly")
        let store = trans.objectStore(TABLE)
        let request = store.get(id)

        request.onsuccess = e => {
          if (e.target.result) {
            resolve(e.target.result)
          } else {
            reject("No entry found for the provided id.")
          }
        }

        request.onerror = e => {
          reject("Error retrieving entry: ", e.target.error)
        }
      })
    })
  },

  /**
   * Adds a single entry.
   *
   * @param {Object} entry
   * @returns {Promise<void>}
   */
  async addEntry(entry) {
    let db = await this.getDb()

    return new Promise(resolve => {
      let trans = db.transaction([TABLE], "readwrite")
      trans.oncomplete = () => {
        resolve(entry)
      }
      trans.onerror = () => {
        reject(trans.error)
      }

      let store = trans.objectStore(TABLE)
      let request = store.put({ ...entry })

      request.onsuccess = () => {
        resolve(entry)
      }
      request.onerror = () => {
        reject(request.error)
      }
    })
  },

  /**
   * Adds multiple entries.
   *
   * @param {Array} entries
   * @returns {Promise}
   */
  async addEntries(entries) {
    let db = await this.getDb()

    return new Promise(resolve => {
      let trans = db.transaction([TABLE], "readwrite")
      trans.oncomplete = () => {
        resolve()
      }

      let store = trans.objectStore(TABLE)
      entries.forEach(entry => {
        store.put({ ...entry })
      })
    })
  }
}
