import { LocalStorage } from "quasar"

/**
 * Helper to update data object.
 *
 * @param state
 * @param data
 */
export function update(state, data) {
  Object.keys(data).forEach(key => {
    if (!key.includes(".")) {
      state[key] = data[key]
    } else {
      updateDeep(state, key.split("."), data[key])
    }
  })
}

/**
 * Helper to update nested data object.
 *
 * @param obj
 * @param keys
 * @param value
 * @returns {*}
 */
function updateDeep(obj, keys, value) {
  if (keys.length === 1) {
    obj[keys[0]] = value
  } else {
    let currentKey = keys.shift()
    obj[currentKey] = updateDeep(obj[currentKey], keys, value)
  }
  return obj
}

/**
 * Set orders list to local storage.
 *
 * @param state
 * @param formdata
 */
export function SET_ORDERS(state, formdata) {
  // fix if formdata is string null
  if (formdata === "null") {
    formdata = null
  }

  LocalStorage.set("orders", formdata)
  state.orders = formdata
}
