import { Notify } from "quasar"


/**
 * Get list of Folders with Projects.
 *
 * @param context
 * @param form
 * @returns {Promise<unknown>}
 */
export async function fetchFolders({dispatch, commit}, form) {
  return await dispatch(
    "app/apiRequest",
    {
      url: "folders",
      method: "GET",
      data: form
    },
    { root: true }
  )
    .then(async response => {
      await commit("SET_FOLDERS", response.data)
      return Promise.resolve(response.data)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

/**
 * Get list of projects.
 *
 * @param context
 * @param form
 * @returns {Promise<unknown>}
 */
export async function fetchProjects({dispatch, commit}, form) {
  return await dispatch(
      "app/apiRequest",
      {
        url: "projects",
        method: "GET",
        data: form
      },
      { root: true }
    )
    .then(async response => {
      await commit("SET_PROJECTS", response.data)
      return Promise.resolve(response.data)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

/**
 * Get a Project.
 *
 * @param context
 * @param form
 * @returns {Promise<unknown>}
 */
export async function get(context, form) {
  return await context
    .dispatch(
      "app/apiRequest",
      {
        url: "projects/" + form.id,
        method: "GET"
      },
      { root: true }
    )
    .then(response => {
      return Promise.resolve(response.data)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

/**
 * Update phase.
 *
 * @param context
 * @param form
 * @returns {Promise<unknown>}
 */
export async function update({ dispatch }, data) {
  return await dispatch(
      "app/apiRequest",
      {
        url: "projects/" + data.project.id,
        method: "PUT",
        data: {
          project: data.project
        }
      },
      { root: true }
    )
    .then(async response => {
      Notify.create({ message: "Project Updated" })
      await dispatch("fetchProjects")
      return Promise.resolve(response.data)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

/**
 * Create Project.
 *
 * @param dispatch
 * @param state
 * @returns {Promise<*>}
 */
export async function store({dispatch}, data) {
  return await dispatch(
      "app/apiRequest",
      {
        url: "projects",
        method: "POST",
        data: data
      },
      { root: true }
    )
    .then(async response => {
      Notify.create({ message: "Project Created" })
      await dispatch("fetchProjects")
      return Promise.resolve(response.data)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

/**
 * Delete Project.
 *
 * @param context
 * @param data
 * @returns {Promise<unknown>}
 */
export async function destroy({dispatch}, data) {
  return await dispatch(
      "app/apiRequest",
      {
        url: "projects/" + data.project.id,
        method: "DELETE",
        data: {
          hard: data.hard ?? false
        }
      },
      { root: true }
    )
    .then(async response => {
      Notify.create({ message: "Project Deleted" })

      await dispatch("fetchProjects")

      return Promise.resolve(response.data)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}


/**
 * Get list of Folders with Projects.
 *
 * @param context
 * @param form
 * @returns {Promise<unknown>}
 */
export async function createFolder({dispatch, commit}, { name }) {
  return await dispatch(
    "app/apiRequest",
    {
      url: "folders",
      method: "POST",
      data: {
        name
      }
    },
    { root: true }
  )
    .then(async response => {
      return Promise.resolve(response.data)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

/**
 * Update Folder Projects.
 * @param dispatch
 * @param commit
 * @param id
 * @param projects
 * @returns {Promise<*>}
 */
export async function updateFolderProjects({dispatch, commit}, { id, projects }) {
  return await dispatch(
    "app/apiRequest",
    {
      url: "folders/" + id + "/projects",
      method: "PUT",
      data: {
        projects
      }
    },
    {root: true}
  )
    .then(async response => {
      return Promise.resolve(response.data)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

/**
 * Update Project Folders.
 * @param dispatch
 * @param commit
 * @param id
 * @param projects
 * @returns {Promise<*>}
 */
export async function updateProjectFolders({dispatch, commit}, { id, folders }) {
  return await dispatch(
    "app/apiRequest",
    {
      url: "projects/" + id + "/folders",
      method: "PUT",
      data: {
        folders
      }
    },
    {root: true}
  )
    .then(async response => {
      return Promise.resolve(response.data)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

/**
 * Update Folder.
 * @param dispatch
 * @param commit
 * @param id
 * @param name
 * @returns {Promise<*>}
 */
export async function renameFolder({dispatch, commit}, { id, name }) {
  return await dispatch(
    "app/apiRequest",
    {
      url: "folders/" + id,
      method: "PUT",
      data: {
        name
      }
    },
    { root: true }
  )
    .then(async response => {
      return Promise.resolve(response.data)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

/**
 * Delete Folder.
 * @param dispatch
 * @param commit
 * @param id
 * @returns {Promise<*>}
 */
export async function deleteFolder({dispatch, commit}, { id }) {
  return await dispatch(
    "app/apiRequest",
    {
      url: "folders/" + id,
      method: "DELETE"
    },
    { root: true }
  )
    .then(async response => {
      return Promise.resolve(response.data)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

// fetchProject
export async function fetchProject({dispatch, commit}, {project_id}) {
  return await dispatch(
    "app/apiRequest",
    {
      url: "projects/" + project_id,
      method: "GET"
    },
    { root: true }
  )
    .then(async response => {
      console.log("fetchProject", response.data.columns);
      await commit("SET_CURRENT_PROJECT", response.data)
      return Promise.resolve(response.data)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

// fetchRows
export async function fetchRows({dispatch, commit}, {project_id, filters}) {
  console.log("fetchRows", project_id, filters)
  return await dispatch(
    "app/apiRequest",
    {
      url: "projects/" + project_id + "/rows",
      method: "GET",
      data: {
        filters: filters.join("|")
      }
    },
    { root: true }
  )
    .then(async response => {
      await commit("SET_ROWS", response.data)
      return Promise.resolve(response.data)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

// v2 - get unique phases from rows
export async function fetchProjectPhases({dispatch, commit, rootGetters}, {project_id}) {

  const currentTeam = rootGetters["auth/currentTeam"];

  return await dispatch(
    "app/apiRequest",
    {
      url: `v2/teams/${currentTeam.id}/projects/${project_id}/phases`,
      method: "GET"
    },
    { root: true }
  )
    .then(async response => {
      return Promise.resolve(response.data)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}
