import { LocalStorage } from "quasar"

/**
 * Helper to update data object.
 *
 * @param state
 * @param data
 */
export function update(state, data) {
  Object.keys(data).forEach(key => {
    if (!key.includes(".")) {
      state[key] = data[key]
    } else {
      updateDeep(state, key.split("."), data[key])
    }
  })
}

/**
 * Helper to update nested data object.
 *
 * @param obj
 * @param keys
 * @param value
 * @returns {*}
 */
function updateDeep(obj, keys, value) {
  if (keys.length === 1) {
    obj[keys[0]] = value
  } else {
    let currentKey = keys.shift()
    obj[currentKey] = updateDeep(obj[currentKey], keys, value)
  }
  return obj
}

/**
 * Set folders list to store.
 *
 * @param state
 * @param folders
 */
export function SET_FOLDERS(state, folders) {
  // fix if projects is string null
  if (folders === "null") {
    folders = null
  }

  LocalStorage.set("folders", folders)
  state.folders = folders
}

/**
 * Set projects list to store.
 *
 * @param state
 * @param projects
 */
export function SET_PROJECTS(state, projects) {
  // fix if projects is string null
  if (projects === "null") {
    projects = null
  }

  LocalStorage.set("projects", projects)
  state.projects = projects
}

// SET_CURRENT_PROJECT
export function SET_CURRENT_PROJECT(state, project) {
  LocalStorage.set("currentProject", project)
  state.currentProject = project
}

export function SET_ROWS(state, rows) {
  LocalStorage.set("rows", rows)
  state.rows = rows
}
