import { LocalStorage } from "quasar"

export default function () {
  return {
    // this should always represent what's persisted in database
    phases: LocalStorage.getItem("phase.phases") || [],

    // this is the current phase being viewed/edited
    currentPhase: {},

    // used when creating a new phase
    blankPhase: {
      project_id: null,
      team: null,
      photos: [],
      rows: []
    },

    // visibility of reusable numpad for inch inputs in data table
    numpadVisible: false,

    // the selected rows in the data table
    selectedRows: [],

    // used to help with shift-clicking to toggle selection of multiple rows
    lastSelectedRow: null,

    queue: []
  }
}
