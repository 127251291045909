/**
 * Just the IDs and Names of phases.
 *
 * @param state
 * @returns {*}
 */
export function list(state){
  return state.phases.map(o => ({id: o.id, name: o.name}))
}

export function phases(state) {
  return state.phases
}

export function currentPhase(state) {
  return state.currentPhase
}

/**
 * Get phases by project id.
 *
 * @param state
 * @param getters
 * @returns array
 */
export const getPhasesByProjectId = (state, getters) => project_id => {
  if (!Array.isArray(getters.phases)) return []

  return getters.phases.filter(phase => {
    return phase.project_id === project_id
  })
}

/**
 * Get types installed/total counts.
 *
 * @param state
 * @returns {(function(*): void)|*}
 */
export const getTypeCounts =
  state =>
  (rows = []) => {
    let counts = []

    // get unique types in rows
    const types = [...new Set(rows.map(item => item?.type || 'unspecified'))]

    types.forEach(type => {
      counts.push({
        type: type,
        count: rows.filter(o => o?.type === type).length
      })
    })

    return counts
  }


/**
 * Get rows that have not been installed in the current phase.
 *
 * @param state
 * @param getters
 * @param rootState
 * @param rootGetters
 * @returns {*[]}
 */
export const uninstalledRows = (state, getters, rootState, rootGetters) => {
  const rows = rootGetters["phase/currentPhase"].rows || []

  return rows.filter(row => {
    if(typeof row?.installed === "object" && row.installed?.value === false) {
      return row
    }
    else if(!row.installed) {
      return row
    }
  })
}

/**
 * Get phase by id
 */
export const getPhaseById =
  (state, getters) =>
  ({ phaseId }) => {
    const phase = getters.phases.find(o => String(o.id) === String(phaseId))
    return phase ? JSON.parse(JSON.stringify(phase)) : null
  }

/**
 * Get currently loaded phase.
 */
export const getCurrentPhase = state => {
  return state.currentPhase
}

/**
 * Get photos for a cell.
 */
export const getPhotosForCell =
  (getters) =>
  ({ column_id, rowid }) => {
    const phase = getters.currentPhase

    const row = phase.rows.find(o => String(o.rowid) === String(rowid))
    if (!row) return []

    const photos = row[column_id]
    if (!photos) return []

    return photos || []
  }

/**
 * Get queued items pending to be sent to backend.
 */
export const getQueue = state => {
  return state.queue
}
