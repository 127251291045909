/**
 * Sets network status to app store. Must have the setOnline mutation in the `app` store.
 * @param store
 */

export default ({ store }) => {

  const updateOnlineStatus = () => {
    store.commit('app/SET_ONLINE', navigator.onLine, { root: true });
  }

  window.addEventListener('online', updateOnlineStatus);
  window.addEventListener('offline', updateOnlineStatus);

  // set initial value
  updateOnlineStatus();
}
