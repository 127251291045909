import { Cookies, LocalStorage } from "quasar"

export default function () {
  return {
    systemColumnsTemplates: LocalStorage.getItem("systemColumnsTemplates")
      ? LocalStorage.getItem("systemColumnsTemplates")
      : null,
    teamColumnsTemplates: LocalStorage.getItem("teamColumnsTemplates")
      ? LocalStorage.getItem("teamColumnsTemplates")
      : null
  }
}
