import {Notify} from "quasar"
import imagesDB from "src/indexDB/imagesDB"
import {s3} from "boot/axios"

const xmlParser = require("fast-xml-parser")
import {debounce} from 'lodash';

const debouncedSendUpdates = debounce(async ({dispatch, commit, state}) => {
  if (state.queue.length) {

    // where queue status is 'pending'
    const queue = state.queue.filter(item => item.status === "pending")
    const phase_id = state.currentPhase.id;

    // set queued items to 'sent' status
    commit('SET_QUEUE_ITEMS_STATUS', {queue_items: queue, status: "sent"});

    // Send the batch request
    await dispatch("app/apiRequest", {
      url: "phases/" + phase_id + "/batch",
      method: "POST",
      data: {queue}
    }, {root: true})
    .then(async response => {
      commit('CLEAR_QUEUE', response.data.queue_ids_processed);
      return Promise.resolve(response.data)
    })
    .catch(error => {
      // if request failed, set queued items back to 'pending' status to try again with next batch
      commit('SET_QUEUE_ITEMS_STATUS', {queue_items: queue, status: "pending"});
      return Promise.reject(error)
    })
  }
}, 1000);

/**
 * Initializes the Pusher subscription and event listeners for phase updates.
 *
 * @param {Object} context - The Vuex store context.
 * @param {Object} payload - The payload object.
 * @param {string} payload.phaseId - The ID of the phase.
 * @returns {Promise<void>} - A promise that resolves when the initialization is complete.
 */
export async function initPusher({getters, rootGetters, commit, state, dispatch}, {phaseId}) {
  Pusher.logToConsole = false

  // remove existing pusher subscription
  this.$pusher.unsubscribe("phase-updates");

  // subscribe to phase updates
  const channel = this.$pusher.subscribe("phase-updates")

  // don't listen to events triggered by this inst
  const currentInstanceId = `${rootGetters["app/instanceId"]}`

  channel.bind(`phase${phaseId}.phase-updated`, async ({phase_id, instance_id}) => {
    console.log("Pusher event received (phase-updated):", {phase_id, instance_id})
    if (state.currentPhase.id === phase_id && instance_id !== currentInstanceId) {
      await dispatch("get", {phaseId: phase_id})
      // await dispatch("setCurrentPhase", {phaseId: phase_id})
    }
  })

  channel.bind(`phase${phaseId}.cell-saved`, ({phase_id, column_id, rowid, value, user_id, instance_id}) => {
    console.log("Pusher event received (cell-saved):", {phase_id, column_id, rowid, value, user_id, instance_id})
    if (state.currentPhase.id === phase_id && instance_id !== currentInstanceId) {
      commit("SET_CELL_VALUE", {phase_id, column_id, rowid, value})
    }
  })

  channel.bind(`phase${phaseId}.columns-changed`, ({phase_id, columns, user_id, instance_id}) => {
    console.log("Pusher event received (columns-changed):", {phase_id, columns, user_id, instance_id})
    if (state.currentPhase.id === phase_id && instance_id !== currentInstanceId) {
      commit("UPDATE_PHASE_COLUMNS", {columns})
    }
  })

  channel.bind(`phase${phaseId}.row-added`, ({phase_id, row, user_id, instance_id, index}) => {
    console.log("Pusher event received (row-added):", {phase_id, row, user_id, instance_id, index});
    if (state.currentPhase.id === phase_id && instance_id !== currentInstanceId) {
      commit("ADD_ROW", {row, index})
    }
  })

  channel.bind(`phase${phaseId}.rows-deleted`, ({phase_id, rowids, user_id, instance_id}) => {
    console.log("Pusher event received (rows-deleted):", {phase_id, rowids, user_id, instance_id});
    if (state.currentPhase.id === phase_id && instance_id !== currentInstanceId) {
      commit("DELETE_ROWS_BY_ROWID", {rowids})
    }
  })
}

/**
 * Get list of phases.
 *
 * @param context
 * @param data
 * @returns {Promise<unknown>}
 */
export async function fetchPhases({dispatch, commit}, {projectId}) {
  return await dispatch(
    "app/apiRequest",
    {
      url: "phases",
      method: "GET",
      data: {project_id: projectId}
    },
    {root: true}
  )
    .then(async response => {
      await commit("SET_PHASES", {data: response.data})
      return Promise.resolve(response.data)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

/**
 * Get a phase.
 *
 * @param context
 * @param formdata
 * @returns {Promise<unknown>}
 */
export async function get({dispatch, commit}, {phaseId}) {
  console.log("phase/get", phaseId)

  return await dispatch(
    "app/apiRequest",
    {
      url: "phases/" + phaseId,
      method: "GET"
    },
    {root: true}
  )
    .then(async response => {

      console.log("phase/get response", response.data)

      // commit fresh phase data to currentPhase state
      await commit("SET_CURRENT_PHASE", response.data)

      return Promise.resolve(response.data)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

/**
 * Update phase.
 *
 * @param context
 * @returns {Promise<unknown>}
 * @param data
 */
export async function update({dispatch, commit}, data) {
  return await dispatch(
    "app/apiRequest",
    {
      url: "phases/" + data.phase.id,
      method: "PUT",
      data: {
        phase: data.phase
      }
    },
    {root: true}
  )
    .then(async response => {
      commit("UPDATE_PHASE", {phase: response.data})
      return Promise.resolve(response.data)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

/**
 * Create phase.
 *
 * @returns {Promise<*>}
 * @param context
 * @param data
 */
export async function store({dispatch}, data) {
  return await dispatch(
    "app/apiRequest",
    {
      url: "phases/create",
      method: "POST",
      data: data
    },
    {root: true}
  )
    .then(response => {
      Notify.create({message: "Phase Created"})
      this.$router.replace("/project/" + response.data.project_id + "/phase/" + response.data.id)

      dispatch("fetchPhases", {project_id: response.data.project_id})

      return Promise.resolve(response.data)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

/**
 * Delete phase.
 *
 * @param context
 * @param data
 * @returns {Promise<unknown>}
 */
export async function deletePhase({dispatch, commit}, {phaseId, projectId}) {
  return await dispatch("app/apiRequest", {
    url: "phases/" + phaseId,
    method: "DELETE"
  }, {root: true})
    .then(response => {
      Notify.create({message: "Phase Deleted"})
      dispatch("fetchPhases", {projectId: projectId})
      return Promise.resolve(response.data)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

/**
 * Saves updated value of a cell.
 *
 * @param {Object} context - The Vuex store context object.
 * @param {Object} payload - The payload object containing the necessary data.
 * @param {string} payload.phase_id - The ID of the phase.
 * @param {string} payload.column_id - The ID of the column.
 * @param {string} payload.rowid - The ID of the row.
 * @param {any} payload.value - The value to be saved in the cell.
 * @returns {Promise<any>} - A promise that resolves to the response data or rejects with an error.
 */
export async function saveCell(context, {phase_id, column_id, rowid, value, localOnly = false}) {
  const { commit } = context;

  // create hash for cell using column_id and rowid
  const cellHash = `${column_id}:${rowid}`
  commit("SET_CELL_VALUE", {phase_id, column_id, rowid, value})

  if(!localOnly) {
    commit("ADD_TO_QUEUE", {type: "cell", cellHash, payload: {column_id, rowid, value}})
    debouncedSendUpdates(context);
  }
}

/**
 * Saves the cell locally (without sending to backend).
 * Used to save cells when offline or if additional processing of the values are needed (like images that need to be uploaded to a server first before saving the cell value)
 *
 * @param {Object} cell - The cell object to be saved.
 * @returns {void}
 */
export async function saveCellLocally({dispatch}, data) {
  dispatch("saveCell", {...data, localOnly: true})
}

/**
 * Updates rows with changed row values by rowid, while keeping the rest of the row data intact.
 */
export async function updateCurrentPhaseRows({dispatch, commit, getters}, {phase_id, rows}) {

  const changedRows = JSON.parse(JSON.stringify(rows))
  const currentPhase = JSON.parse(JSON.stringify(await getters['getCurrentPhase']));

  const updatedRows = currentPhase.rows.map(row => {
    const updatedRow = changedRows.find(r => r.rowid === row.rowid)
    if (updatedRow) {
      return {...row, ...updatedRow}
    }
    return row
  })

  // commit the updated rows to state
  commit("UPDATE_PHASE_ROWS", {rows: updatedRows})

  // update db with latest phase data
  return dispatch('update', {phase: JSON.parse(JSON.stringify(await getters['getCurrentPhase']))})
}

/**
 * Toggles the visibility of the custom on-screen numpad.
 *
 * @param {Object} context - The Vuex context object.
 * @param {boolean} isVisible - The visibility state of the numpad.
 */
export function toggleNumpadVisibility({commit}, isVisible) {
  commit("SET_NUMPAD_VISIBILITY", isVisible)
}

/**
 * Set current phase.
 *
 * @param context
 * @param phaseId
 * @returns {Promise<unknown>}
 */
export function setCurrentPhase({getters, commit}, {phaseId}) {
  // get phase by id
  const phase = getters.getPhaseById({phaseId})

  console.log("setCurrentPhase", phase)

  return commit("SET_CURRENT_PHASE", phase)
}

/**
 * Reorders the columns in the current phase.
 *
 * @param {Object} context - The Vuex store context.
 * @param {Object} payload - The payload containing the old and new index of the column.
 * @param {Function} context.dispatch - The Vuex dispatch function.
 * @param {Function} context.commit - The Vuex commit function.
 * @param {Object} context.state - The Vuex state object.
 * @param {number} payload.oldIndex - The old index of the column.
 * @param {number} payload.newIndex - The new index of the column.
 * @returns {Promise} A promise that resolves with the response data or rejects with an error.
 */
export async function reorderColumns({dispatch, commit, state}, {oldIndex, newIndex}) {

  if (newIndex < 0 || newIndex >= state.currentPhase.columns.length) {
    return
  }

  let columns = JSON.parse(JSON.stringify(state.currentPhase.columns))

  const [item] = columns.splice(oldIndex, 1)
  columns.splice(newIndex, 0, item)

  columns.forEach((column, index) => {
    column.position = index
  })

  await commit("UPDATE_PHASE_COLUMNS", {columns})

  await dispatch(
    "app/apiRequest",
    {
      url: "phases/" + state.currentPhase.id + "/columns",
      method: "POST",
      data: {columns}
    },
    {root: true}
  )
    .then(async response => {
      return Promise.resolve(response.data)
    })
    .catch(error => {
      // todo: undo the optimistic commit above

      return Promise.reject(error)
    })
}

export async function resizeColumn({dispatch, commit, state}, {column_id, width}) {
  let columns = JSON.parse(JSON.stringify(state.currentPhase.columns))

  const index = columns.findIndex(c => c.id === column_id)

  // no less than 50px
  columns[index].width = Math.max(50, width)

  await commit("UPDATE_PHASE_COLUMNS", {columns})

  await dispatch(
    "app/apiRequest",
    {
      url: "phases/" + state.currentPhase.id + "/columns",
      method: "POST",
      data: {columns}
    },
    {root: true}
  )
    .then(async response => {
      return Promise.resolve(response.data)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

// reorder row
export async function reorderRows({dispatch, commit, state}, {oldIndex, newIndex}) {
  let rows = JSON.parse(JSON.stringify(state.currentPhase.rows))

  const [item] = rows.splice(oldIndex, 1)
  rows.splice(newIndex, 0, item)

  await commit("UPDATE_PHASE_ROWS", {rows})

  // await dispatch(
  //   "app/apiRequest",
  //   {
  //     url: "phases/" + state.currentPhase.id + "/rows",
  //     method: "POST",
  //     data: { rows }
  //   },
  //   { root: true }
  // )
  //   .then(async response => {
  //     return Promise.resolve(response.data)
  //   })
  //   .catch(error => {
  //
  //     return Promise.reject(error)
  //
  //   })
}


/**
 * Adds a row to the phase.
 *
 * @param {Object} context - The Vuex context object.
 * @param {Object} payload - The payload object.
 * @param {number} payload.phase_id - The ID of the phase. If not provided, the current phase ID will be used.
 * @param {Object} payload.row - The row object to be added.
 * @param {Object} context.state - The Vuex state object.
 * @param {Function} context.commit - The Vuex commit function.
 * @param {Function} context.dispatch - The Vuex dispatch function.
 * @returns {Promise} A promise that resolves with the response data if successful, or rejects with an error if unsuccessful.
 */
export async function addRow(context, {row = {}, index = null}) {

  const {commit} = context;
  let newRowData = JSON.parse(JSON.stringify(row))

  // assign temporary id to reference if persisting to db is unsuccessful
  const rowid = `row_${Date.now()}_${Math.floor(Math.random() * 100000)}`

  newRowData.rowid = `${rowid}`
  commit("ADD_ROW", {row: newRowData, index})

  commit("ADD_TO_QUEUE", {type: "newRow", rowid, payload: {row: newRowData, index}})
  debouncedSendUpdates(context);
}

/**
 * Deletes row(s) by rowid.
 *
 * @param {Object} context - The Vuex action context object.
 * @param {Object} payload - The payload object containing the phase ID and row ID.
 * @param {Object} context.state - The Vuex state object.
 * @param {Function} context.commit - The Vuex commit function.
 * @param {Function} context.dispatch - The Vuex dispatch function.
 * @param {number} payload.phase_id - The ID of the phase to delete the row from (optional).
 * @param {number} payload.rowid - The ID of the row to delete.
 * @returns {Promise<Object>} A promise that resolves to the response data from the server.
 * @throws {Error} If an error occurs during the API request.
 */
export async function deleteRowsByIndex({state, commit, dispatch}, {phase_id, row_indexes}) {
  // if phase_id is not passed, use current phase
  if (phase_id === undefined) {
    phase_id = state.currentPhase.id
  }

  // get rowids from state.phase.rows using row_indexes
  const rowids = row_indexes.map(index => state.currentPhase.rows[index].rowid)

  // temporary optimistic commit
  await commit("DELETE_ROWS_BY_INDEX", row_indexes)

  // send to server
  await dispatch(
    "app/apiRequest",
    {
      url: "phases/" + phase_id + "/rows",
      method: "DELETE",
      data: {rowids}
    },
    {root: true}
  )
    .then(async response => {
      // updated rows from db
      const rows = response.data.rows
      commit("UPDATE_PHASE_ROWS", {rows})

      return Promise.resolve(response.data)
    })
    .catch(error => {
      // todo: undo the optimistic commit above

      return Promise.reject(error)
    })
}

/**
 * Toggle row selection.
 *
 * @param context
 * @param rowIndex
 */
export function toggleRowSelection({commit}, rowIndex) {
  commit("TOGGLE_ROW_SELECTION", rowIndex)
}

/**
 * Set selected rows.
 *
 * @param context
 * @param selectedRows
 */
export function toggleSelectedRows({commit, state}, {rows, selected}) {
  // add to selectedRows
  if (selected) {
    // prevent duplicates
    const newSelectedRows = Array.from(new Set([...state.selectedRows, ...rows]))
    commit("SET_SELECTED_ROWS", newSelectedRows)
  }
  // unselect rows
  else {
    const newSelectedRows = state.selectedRows.filter(row => !rows.includes(row))
    commit("SET_SELECTED_ROWS", newSelectedRows)
  }
}

/**
 * Clear selected rows.
 *
 * @param context
 */
export function clearSelectedRows({commit}) {
  commit("CLEAR_SELECTED_ROWS")
}

/**
 * Sets the last selected index in datatable. Used for shift-click selection/deselection.
 *
 * @param {Object} context - The Vuex context object.
 * @param {number} index - The index to set as the last selected index.
 */
export function setLastSelectedRow({commit}, index) {
  commit("SET_LAST_SELECTED_ROW", index)
}

// saveColumns
export async function saveColumn({commit, dispatch, state}, {phase_id = state.currentPhase.id, column}) {
  console.log("saveColumn", phase_id, column)

  let columns = [...state.currentPhase.columns]
  let index = columns.findIndex(c => c.id === column.id)

  // update existing column
  if (index !== -1) {
    columns[index] = column
  }
  // new column
  else {
    columns.push(column)
  }

  await commit("UPDATE_PHASE_COLUMNS", {columns})

  await dispatch(
    "app/apiRequest",
    {
      url: "phases/" + phase_id + "/columns",
      method: "POST",
      data: {columns}
    },
    {root: true}
  )
    .then(async response => {
      await commit("UPDATE_PHASE_COLUMNS", {columns: response.data.columns})
      return Promise.resolve(response.data)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

/**
 * Deletes a column from a phase.
 *
 * @param {Object} context - The context object containing dispatch, and commit.
 * @param {Object} payload - The payload object containing phase_id and column_id.
 * @returns {Promise<Object>} - A promise that resolves to the response data.
 * @throws {Error} - If an error occurs during the API request.
 */
export async function deleteColumn({dispatch, commit, state}, {phase_id = state.currentPhase.id, column_id}) {
  commit("DELETE_COLUMN", column_id)

  await dispatch(
    "app/apiRequest",
    {
      url: "phases/" + phase_id + "/columns",
      method: "DELETE",
      data: {column_ids: [column_id]}
    },
    {root: true}
  )
    .then(async response => {
      const columns = response.data.columns
      commit("UPDATE_PHASE_COLUMNS", {columns})

      return Promise.resolve(response.data)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

export async function uploadLocalImages({state, dispatch}, {photo_id} = {}) {
  console.log("uploadLocalImages()", {photo_id})

  const entries = photo_id ? [await imagesDB.getEntryById(photo_id)] : await imagesDB.getAll()
  let s3FormData = new FormData()

  // check if there are images to upload
  if (!entries) {
    console.log("No images to upload")
    return;
  }

  // if there are images to upload
  if (entries && Array.isArray(entries)) {
    // prepare S3 signature
    const signatureValues = await dispatch("auth/getS3Signature", null, {root: true})
    for (const key in signatureValues) {
      s3FormData.append(key, signatureValues[key])
    }
  }

  await Promise.all(
    entries.map(async entry => {
      let errors = []

      // get this entry's row
      let rows = state.currentPhase.rows.filter(row => row.rowid === entry.rowid)

      // skip if row not found
      if (rows.length !== 1) return

      // cell data
      const row = JSON.parse(JSON.stringify(rows[0]))

      // check if entry exists in the cell
      let cellValue = row[entry.column_id]

      // ensure cellValue is an array
      if (!Array.isArray(cellValue)) {
        // add error to row
        errors.push(`Cell value is not an array: ${entry.rowid}`)
      }

      // photo index in cell
      const indexInCell = cellValue?.findIndex(photo => photo.id === entry.id)

      // skip if photo no longer in cell
      if (indexInCell === -1) return

      // photo in cell
      const photoInCell = cellValue[indexInCell]

      // skip if photo already uploaded (and delete local photo)
      if (photoInCell.isUploaded) {
        await imagesDB.deleteEntry({id: entry.id})
        return
      }

      // upload this photo
      const filenameBody = `uploads/phase_${entry.phase_id}/${entry.column_id}-${entry.rowid}-${Date.now()}`

      // keys
      let imageKey = null

      // update cellValue
      cellValue[indexInCell] = {
        ...photoInCell
      }

      // upload main image
      const filename = `${filenameBody}.jpg`
      s3FormData.set("file", entry.blob, filename)
      await s3
        .post("/", s3FormData, {
          onUploadProgress: async event => {
            let progress = parseFloat((event.loaded / event.total).toFixed(2))
            console.log(`S3 upload progress for rowid ${entry.rowid}`, {progress}, {event})

            await dispatch("updatePhotoUploadProgress", {
              phase_id: entry.phase_id,
              column_id: entry.column_id,
              rowid: entry.rowid,
              photo_id: entry.id,
              progress
            })
          }
        })
        .then(async response => {
          // save image key to cell
          if (xmlParser.validate(response.data) === true) {
            const responseJson = xmlParser.parse(response.data)
            const imageKey = responseJson.PostResponse.Location

            cellValue[indexInCell]["src"] = imageKey
            cellValue[indexInCell]["isUploaded"] = true
          }
        })
        .catch(error => {
          console.error("uploadLocalImages() error", error)
          errors.push(error)
        })

      if (!errors.length) {
        await dispatch("saveCell", {
          phase_id: entry.phase_id,
          column_id: entry.column_id,
          rowid: entry.rowid,
          value: cellValue
        }).then(async () => {
          console.log("photo uploaded and cell updated")

          // delete the local photo
          await imagesDB.deleteEntry({id: entry.id})
          console.log(`Uploaded.... local photo deleted: ${entry.id}`)
        })
      } else {
        console.error("uploadLocalImages() errors", errors)
      }
    })
  )

  console.log("Done uploading local photos!!")
}


export async function updatePhotoUploadProgress({commit, state}, {phase_id, column_id, rowid, photo_id, progress}) {
  // get this entry's row
  let rows = state.currentPhase.rows.filter(row => row.rowid === rowid)

  // skip if row not found
  if (rows.length !== 1) return

  // cell data
  const row = JSON.parse(JSON.stringify(rows[0]))

  // check if entry exists in the cell
  let cellValue = row[column_id]

  // photo in cell
  const photoInCell = cellValue.find(o => o.id === photo_id)

  // skip if photo no longer in cell
  if (!photoInCell) return

  photoInCell.progress = progress

  cellValue = cellValue.map(o => {
    if (o.id === photo_id) {
      return JSON.parse(JSON.stringify(photoInCell))
    }

    return o
  })

  await commit("SET_CELL_VALUE", {phase_id, column_id, rowid, value: cellValue})
}


/**
 * Cascade fill empty cells in a column with the nearest non-empty value above.
 */
export async function cascadeFill({commit, state, dispatch}, column) {
  // get column id
  const column_id = column.id

  // update all rows in this column that are empty with the nearest non-empty value above
  const rows = JSON.parse(JSON.stringify(state.currentPhase.rows))
  let updatedRows = []

  // get the column values
  const columnValues = rows.map(row => row[column_id])

  // loop through rows
  for (let i = 0; i < columnValues.length; i++) {
    let value = columnValues[i]

    // if value is empty, get the nearest non-empty value above
    if (!value) {
      let j = i - 1
      while (j >= 0) {
        if (columnValues[j]) {
          value = columnValues[j]
          break
        }
        j--
      }

      // update the row
      updatedRows.push({rowid: rows[i].rowid, [column_id]: value})
    }
  }

  return dispatch("updateCurrentPhaseRows", {rows: updatedRows})
}

/**
 * Apply subindex values to rows.
 */
export async function applySubindexValues({commit, state, dispatch}, {column_id, subindexValues}) {
  // get column id
  const columnId = column_id

  // update all rows in this column with the subindex values
  const rows = JSON.parse(JSON.stringify(state.currentPhase.rows))
  let updatedRows = []

  // loop through rows
  for (let i = 0; i < rows.length; i++) {
    let row = rows[i]
    let value = subindexValues[row.rowid]

    // update the row
    updatedRows.push({rowid: row.rowid, [columnId]: value})
  }

  return dispatch("updateCurrentPhaseRows", {rows: updatedRows})
}

export async function generateDataTableComputedValues({commit, state, dispatch}) {

  let rowsToUpdate = [];

  const columns = state.currentPhase?.columns || [];
  const rows = state.currentPhase?.rows || [];

  // calculate subindex values (use "for .. of" loop to allow for async/await)
  for (const col of columns.filter(col => col.type === 'subindex')) {

    // make sure grouping_columns is an array
    if (!Array.isArray(col.subindex_config?.groupingColumns)) {
      console.error('grouping_columns is not an array', col);
      return;
    }

    // make sure grouping_columns is not empty
    if (!col.subindex_config?.groupingColumns || !col.subindex_config?.groupingColumns.length) {
      console.error('grouping_columns is is empty', col);
      return;
    }

    // make sure all columns in grouping_columns exist
    if (col.subindex_config?.groupingColumns.some(id => !columns.find(c => c.id === id))) {
      console.error('grouping_columns contains invalid column id', col);
      return;
    }

    // let's get to the meat and iterate through all rows
    let keyCounts = {};
    for (const row of rows) {
      const keyString = col.subindex_config.groupingColumns.map(column_id => String(row[column_id] || '')).join('-');
      // base64 encode to avoid special characters
      const key = btoa(keyString);
      if (!keyCounts[key]) {
        keyCounts[key] = {
          rowid: row.rowid,
          key: keyString,
          count: 1
        };
      } else {
        keyCounts[key].count++;
      }

      const prefix = col.subindex_config?.prefix || '';
      const value = `${prefix}${keyCounts[key].count}`;

      // returns a referenced object to the row object
      let rowToUpdate = rowsToUpdate.find(r => r.rowid === row.rowid);

      // new
      if (!rowToUpdate) {
        rowToUpdate = {rowid: row.rowid, computedValues: {}}
        rowToUpdate["computedValues"][col.id] = value
        rowsToUpdate.push(rowToUpdate)
      }
      // update
      else {
        rowToUpdate["computedValues"][col.id] = value
      }
    }
  }

  return dispatch("updateCurrentPhaseRows", {rows: rowsToUpdate})
}


/**
 * Add photo to photo cell
 */
export async function addPhotoToCell({commit, state, dispatch}, {column_id, rowid, photo}) {
  // get this entry's row
  let rows = state.currentPhase.rows.filter(row => row.rowid === rowid)

  // skip if row not found
  if (rows.length !== 1) return

  // cell data
  const row = JSON.parse(JSON.stringify(rows[0]))

  // ensure cell is an array, otherwise convert to empty array
  let cellValue = Array.isArray(row[column_id]) ? row[column_id] : []

  // add photo to cell
  cellValue.push(photo)

  // update cellValue
  row[column_id] = cellValue

  // update row
  await dispatch("updateCurrentPhaseRows", {rows: [row]})
  // upload
  await dispatch("uploadLocalImages", {photo_id: photo.id})
}


/**
 * Remove photo from photo cell
 */
export async function deletePhotoFromCell({commit, state, dispatch}, {column_id, rowid, photo_id}) {
  // get this entry's row
  let rows = state.currentPhase.rows.filter(row => row.rowid === rowid)

  // skip if row not found
  if (rows.length !== 1) return

  // cell data
  const row = JSON.parse(JSON.stringify(rows[0]))

  // check if entry exists in the cell
  let cellValue = row[column_id]

  // ensure cellValue is an array
  if (!Array.isArray(cellValue)) {
    // add error to row
    console.error(`Cell value is not an array: ${rowid}`)
    return
  }

  // remove photo from cell
  cellValue = cellValue.filter(photo => photo.id !== photo_id)

  // update cellValue
  row[column_id] = cellValue

  // delete local copy if exists
  await imagesDB.deleteEntry({id: photo_id})

  // update row
  await dispatch("updateCurrentPhaseRows", {rows: [row]})
}

// toggleSelectAllRows
export async function toggleSelectAllRows({commit, state}, checked) {
  if (checked) {
    // list of all indexes in currentPhase.rows
    const indexes = state.currentPhase.rows.map((row, index) => index)

    commit("SET_SELECTED_ROWS", indexes)
  } else {
    commit("CLEAR_SELECTED_ROWS")
  }
}


// clonePhase
export async function clonePhase({dispatch}, {id, projectId, newName, includeColumns, includeRows}) {
  return await dispatch(
    "app/apiRequest",
    {
      url: "phases/" + id + "/clone",
      method: "POST",
      data: {
        newName,
        includeColumns,
        includeRows
      }
    },
    {root: true}
  )
    .then(async response => {
      Notify.create({message: "Phase Cloned"})
      dispatch("fetchPhases", {projectId})
      return Promise.resolve(response.data)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}
