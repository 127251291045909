import {DISTO} from "src/constants";

/**
 * Log a message
 */
export function log({commit}, value) {
  commit('ADD_LOG', value);
}

/**
 * Clear logs
 */
export function clearLogs({commit}) {
  commit('CLEAR_LOGS');
}

/**
 * Set scanning state
 */
export function setScanning({dispatch, commit}, value) {
  if(value) {
    dispatch('log', 'Scanning for devices...');
  }

  commit('SET_SCANNING', !!value);
}

/**
 * Set scanning state
 */
export function setReceiving({dispatch, commit}, value) {
  commit('SET_RECEIVING', value);
}

/**
 * Set device
 */
export function setDevice({dispatch, commit}, device) {
  dispatch('log',`Device found: ${device.name}`);
  commit('SET_DEVICE', device);
}

/**
 * Discover bluetooth devices
 */
export async function discoverDevices({dispatch}) {

  dispatch('setScanning', true);

  let filters = [];

  // filter on namePrefix
  filters.push({
    namePrefix: DISTO.NAME_PREFIX
  });
  // *OR* services
  filters.push({
    services: [DISTO.DISTO_SERVICEID]
  });

  let options = {
    optionalServices: [DISTO.DEVICE_INFORMATION, DISTO.BATTERY_SERVICE, DISTO.DISTO_SERVICEID],
    acceptAllDevices: false
  }
  options.filters = filters;

  const device = await navigator.bluetooth.requestDevice(options);

  await dispatch('setDevice', device);
  await dispatch('connectToDevice', device);
  await dispatch('setScanning', false);
  await dispatch('log', 'Notifications have been started.');
}

/**
 * Connect to a device
 */
export async function connectToDevice({dispatch, commit}, device) {

  device.addEventListener('gattserverdisconnected', event => dispatch('onDisconnected', event));

  await dispatch('log',`Connecting to device GATT Server...`);
  const server = await device.gatt.connect();

  await dispatch('log',`Getting device primary service...`);
  const service = await server.getPrimaryService(DISTO.DISTO_SERVICEID);

  await dispatch('log',`Getting Distance Characteristic...`);
  const characteristic = await service.getCharacteristic(DISTO.DISTO_DISTANCE);

  await dispatch('log',`Setting measurement event listener...`);
  characteristic.addEventListener('characteristicvaluechanged', event => dispatch('handleDistanceChanged',event));

  await dispatch('log',`Enabling notifications...`);
  await characteristic.startNotifications();
  await dispatch('log',`Connected to ${device.name}.`);

  commit('SET_CONNECTED', true);
}

/**
 * Handle distance changed event
 */
export async function handleDistanceChanged({dispatch}, event) {
  await dispatch('setReceiving', true);
  const value = event.target.value;
  await dispatch('log',`Got distance: ${value.getFloat32(0, true)}`);



  // add measurement
  await dispatch('addMeasurement', value.getFloat32(0, true));

  // delay .5 seconds to simulate processing
  await new Promise(resolve => setTimeout(resolve, 300));
  await dispatch('setReceiving', false);
}

/**
 * Add measurement
 */
export function addMeasurement({commit}, value) {
  commit('ADD_MEASUREMENT', value);
}

/**
 * Clear measurements
 */
export function clearMeasurements({commit}) {
  commit('CLEAR_MEASUREMENTS');
}

/**
 * Disconnect from device
 */
export async function disconnect({dispatch, commit, state}) {
  if (state.device && state.device.gatt?.connected) {
    await dispatch('log',`Disconnecting from device...`);
    await state.device?.gatt?.disconnect();
    await commit('SET_CONNECTED', false);
    await commit('CLEAR_DEVICE');
  }
}

/**
 * Handle disconnect
 */
export async function onDisconnected({dispatch, commit}, event) {
  const device = event.target;
  await dispatch('log',`Device ${device.name} disconnected.`)
  await commit('SET_CONNECTED', false);
  await commit('CLEAR_DEVICE');
}
