import Pusher from 'pusher-js';

export default ({ app, store }) => {
  const pusher = new Pusher(process.env.PUSHER_APP_KEY, {
    cluster: process.env.PUSHER_APP_CLUSTER,
    encrypted: process.env.PUSHER_APP_ENCRYPTED || true,
  });

  // inject into Vue globals
  app.config.globalProperties.$pusher = pusher;

  // inject into Vuex store
  store.$pusher = pusher;
}
