import { Cookies, LocalStorage } from "quasar"

export default function () {
  return {
    csrfToken: Cookies.get("XSRF-TOKEN") ? Cookies.get("XSRF-TOKEN") : null,
    userToken: LocalStorage.getItem("userToken") ? LocalStorage.getItem("userToken") : null,
    user: LocalStorage.getItem("user") ? LocalStorage.getItem("user") : null,
    teams: LocalStorage.getItem("teams") ? LocalStorage.getItem("teams") : null,
    currentTeam: LocalStorage.getItem("currentTeam") ? LocalStorage.getItem("currentTeam") : null,
    invites: null,
    inviteToken: LocalStorage.getItem("inviteToken") === "null" ? null : LocalStorage.getItem("inviteToken"),
    directS3FormData: LocalStorage.getItem("directS3FormData") ? LocalStorage.getItem("directS3FormData") : null,
  }
}
