export default function () {
  return {
    app: {
      version: "1.0"
    },
    api: {
      pageData: null,
      perPageRecords: 20,
      url: process.env.API_URL
    },
    isOnline: null,
    instanceId: null
  }
}
